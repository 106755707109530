import {
  VisaAccountLow,
  VisaCloseTiny,
  VisaGlobalHigh,
  VisaMenuLow,
  VisaConnectLow,
  VisaWrenchLow,
  VisaInformationLow,
  VisaIssuerLow,
} from "@visa/nova-icons-react";
import { useState, useRef, useEffect } from "react";
import {
  Button,
  Divider,
  DropdownButton,
  Link,
  Nav,
  Tab,
  Tabs,
  Typography,
} from "@visa/nova-react";
import { ProfileInfoModal } from "./ProfileInfoModal";

const HorizontalAdvancedAlternativeNav = () => {
  const [accountIsOpenHorizontal] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [bid, setBid] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [signedId, setSignedIn] = useState(false);
  const [userId, setUserId] = useState("N/A");
  const [userName, setUserName] = useState("N/A");
  const [userEmail, setUserEmail] = useState("N/A");
  const [userRole, setUserRole] = useState("N/A");
  const [currentPage, setCurretPage] = useState("");

  const profileInfoModalRef = useRef();

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      setSignedIn(true);
      setBusinessName(currentUser.userBidName ?? "Test Issuer");
      setBid(currentUser.userBid);
      setUserId(currentUser.volId);
      setUserName(currentUser.firstName + " " + currentUser.lastName);
      setUserEmail(currentUser.userEmail);
      setUserRole(currentUser.userRole);

      setCurrentTabMarker();
    }
  }, []);

  const setCurrentTabMarker = () => {
    if (
      window.location.href.indexOf("/flow/") > -1 ||
      window.location.href.indexOf("/flows") > -1
    ) {
      setCurretPage("flows");
    } else if (window.location.href.indexOf("/config") > -1) {
      setCurretPage("config");
    } else if (window.location.href.indexOf("/about") > -1) {
      setCurretPage("about");
    } else if (window.location.href.indexOf("/sysconfig") > -1) {
      setCurretPage("sysconfig");
    }
  };

  const isAdminUser = () => {
    return userRole === "Admin";
  };

  return (
    <>
      <Nav
        alternate
        className="v-justify-content-between"
        orientation="horizontal"
        tag="header"
      >
        <DropdownButton
          iconTwoColor
          aria-label="open menu"
          buttonSize="large"
          className="v-desktop-container-hide"
          colorScheme={isMobileMenuOpen ? "tertiary" : "tertiary"}
          iconButton
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          id="horizontal-mobile-expand-button"
          aria-controls={
            isMobileMenuOpen ? "horizontal-mobile-menu" : undefined
          }
          aria-owns={isMobileMenuOpen ? "horizontal-mobile-menu" : undefined}
        >
          {isMobileMenuOpen ? (
            <VisaCloseTiny />
          ) : (
            <>
              <VisaMenuLow />
              {/* <BadgeNumber aria-label="3 notifications">3</BadgeNumber> */}
            </>
          )}
        </DropdownButton>
        <Link
          noUnderline
          href="./#/flows"
          className="v-flex v-gap-16"
          aria-label="Visa Application Name Home"
          id="horizontal-go-back-home"
          style={{ backgroundColor: "transparent" }}
          onClick={() => setCurretPage("flows")}
        >
          <VisaGlobalHigh />
          <Typography
            variant="headline-3"
            className="v-xs-container-hide  v-md-container-hide v-mobile-container-hide"
            style={{ color: "var(--v-nav-foreground)" }}
          >
            VBA - Flow Designer Web
          </Typography>

          <Typography
            variant="headline-3"
            className="v-lg-container-hide v-xl-container-hide v-xxl-container-hide"
            style={{ color: "var(--v-nav-foreground)" }}
          >
            VBA Web
          </Typography>

          {signedId && (
            <>
              <Typography>
                {businessName} - {bid}
              </Typography>
            </>
          )}
        </Link>
        <nav
          aria-label="Alternative horizontal nav with logo, links, and footer"
          className="v-ml-auto v-mobile-container-hide"
        >
          <Tabs role="tablist" className="v-gap-8">
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./#/flows">
                    <VisaConnectLow className="v-md-container-hide" />
                    Manage Flows
                  </a>
                }
                role="tab"
                aria-current={currentPage === "flows" ? "page" : null}
                onClick={() => setCurretPage("flows")}
              />
            </Tab>
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./#/config">
                    <VisaIssuerLow className="v-md-container-hide" />
                    Client Conf.
                  </a>
                }
                role="tab"
                aria-current={currentPage === "config" ? "page" : null}
                onClick={() => setCurretPage("config")}
              />
            </Tab>
            {isAdminUser() && (
              <Tab role="none">
                <Button
                  iconTwoColor
                  buttonSize="large"
                  colorScheme="tertiary"
                  element={
                    <a href="./#/sysconfig">
                      <VisaWrenchLow className="v-md-container-hide" />
                      System Conf.
                    </a>
                  }
                  role="tab"
                  aria-current={currentPage === "sysconfig" ? "page" : null}
                  onClick={() => setCurretPage("sysconfig")}
                />
              </Tab>
            )}
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./#/about">
                    <VisaInformationLow className="v-md-container-hide" />
                    About
                  </a>
                }
                role="tab"
                aria-current={currentPage === "about" ? "page" : null}
                onClick={() => setCurretPage("about")}
              />
            </Tab>
          </Tabs>
        </nav>
        <div className="v-flex v-gap-8 v-ml-8">
          <Tab className="v-mobile-container-hide" tag="div">
            <Button
              colorScheme="tertiary"
              iconTwoColor
              buttonSize="large"
              aria-label="Alex Miller"
              aria-expanded={accountIsOpenHorizontal}
              aria-current={accountIsOpenHorizontal && "page"}
              className="v-mobile-container-hide"
              onClick={() =>
                signedId && profileInfoModalRef.current?.showModal()
              }
            >
              <VisaAccountLow />
            </Button>
          </Tab>
        </div>
      </Nav>
      {isMobileMenuOpen && (
        <Nav
          className="v-alternate v-desktop-container-hide"
          role="listbox"
          id="horizontal-mobile-menu"
          aria-labelledby="horizontal-mobile-expand-button"
          orientation="vertical"
          aria-label="Horizontal Nav with Logo, Application, Links, & Buttons"
        >
          <Tabs role="tablist" orientation="vertical">
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./nav">
                    <VisaConnectLow />
                    Manage Flows
                  </a>
                }
                role="tab"
                aria-current={currentPage === "flows" ? "page" : null}
                onClick={() => setCurretPage("flows")}
              ></Button>
            </Tab>
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./#/config">
                    <VisaIssuerLow />
                    Client Configuration
                  </a>
                }
                role="tab"
                aria-current={currentPage === "config" ? "page" : null}
                onClick={() => setCurretPage("config")}
              />
            </Tab>
            {isAdminUser() && (
              <Tab role="none">
                <Button
                  iconTwoColor
                  buttonSize="large"
                  colorScheme="tertiary"
                  element={
                    <a href="./#/sysconfig">
                      <VisaWrenchLow />
                      System Configuration
                    </a>
                  }
                  role="tab"
                  aria-current={currentPage === "sysconfig" ? "page" : null}
                  onClick={() => setCurretPage("sysconfig")}
                />
              </Tab>
            )}
            <Tab role="none">
              <Button
                iconTwoColor
                buttonSize="large"
                colorScheme="tertiary"
                element={
                  <a href="./#/about">
                    <VisaInformationLow />
                    About
                  </a>
                }
                role="tab"
                aria-current={currentPage === "about" ? "page" : null}
                onClick={() => setCurretPage("about")}
              />
            </Tab>
            <Divider dividerType="decorative"></Divider>
            <Tab tag="div">
              <Button
                colorScheme="tertiary"
                iconTwoColor
                buttonSize="large"
                aria-label="Alex Miller"
                aria-expanded={accountIsOpenHorizontal}
                aria-current={accountIsOpenHorizontal && "page"}
                onClick={() =>
                  signedId && profileInfoModalRef.current?.showModal()
                }
              >
                <VisaAccountLow />
              </Button>
            </Tab>
          </Tabs>
        </Nav>
      )}
      <ProfileInfoModal
        ref={profileInfoModalRef}
        bid={bid}
        businessName={businessName}
        userId={userId}
        userName={userName}
        userEmail={userEmail}
        userRole={userRole}
        setSignedIn={setSignedIn}
      />
    </>
  );
};

export default HorizontalAdvancedAlternativeNav;
