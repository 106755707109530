import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeader,
  Label,
  Input,
  Textarea,
  InputContainer,
  Select,
  InputControl,
} from "@visa/nova-react";
import { VisaChevronDownTiny } from "@visa/nova-icons-react";
import useFocusTrap from "../../../utils/useFocusTrap.tsx";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import apiConfig from "../../../utils/apiConfig";

export const AddNewFlowModal = forwardRef(
  (
    {
      templates,
      bid,
      selectedFlowRecord,
      loadFlows,
      isEdit,
      displaySuccessMessage,
      displayErrorMessage,
    },
    fref
  ) => {
    const [name, setName] = useState("New Flow");
    const [desc, setDesc] = useState("Flow Description");
    const [flowId, setFlowId] = useState(-1);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { onKeyNavigation, ref } = useFocusTrap();

    useEffect(() => {
      setSelectedTemplate(templates[0]?.flowTemplateId);
    }, []);

    useEffect(() => {
      setName(selectedFlowRecord ? selectedFlowRecord.flowName : "New Flow");
      setDesc(
        selectedFlowRecord
          ? selectedFlowRecord.flowDescription
          : "Flow Description"
      );
      setFlowId(selectedFlowRecord ? selectedFlowRecord.flowId : -1);
    }, [selectedFlowRecord]);

    useImperativeHandle(fref, () => ({
      showModal() {
        ref.current?.showModal();
      },
      closeModal() {
        ref.current?.close();
      },
    }));

    const submitCreate = () => {
      console.log("SUBMIT");

      if (isEdit === true) {
        apiConfig
          .post(
            "/banking-assistant-web/v1/flowdata/bid/" +
              bid +
              "/updateFlow/" +
              selectedFlowRecord.flowId,
            {
              name: name,
              desc: desc,
            }
          )
          .then((res) => {
            console.log(res.data);
            displaySuccessMessage("Flow Successfully Updated");
            loadFlows();
            ref.current?.close();
          })
          .catch(function (error) {
            console.log(error);
            displayErrorMessage("Could not save Flow");
            ref.current?.close();
          });
      } else {
        apiConfig
          .post(
            "/banking-assistant-web/v1/flowdata/bid/" + bid + "/createflow",
            {
              name: name,
              desc: desc,
              template: selectedTemplate ?? templates[0]?.flowTemplateId,
            }
          )
          .then((res) => {
            console.log(res.data);
            displaySuccessMessage("Flow Successfully Created");
            loadFlows();
            ref.current?.close();
          })
          .catch(function (error) {
            console.log(error);
            displayErrorMessage("Could not save Flow");
            ref.current?.close();
          });
      }
    };

    return (
      <>
        <Dialog
          aria-describedby="add-new-flow-dialog-description"
          aria-labelledby="add-new-flow-dialog-title"
          id="dialog"
          ref={ref}
          onKeyDown={(e) => onKeyNavigation(e, ref.current?.open)}
        >
          <DialogContent>
            {isEdit === true && (
              <DialogHeader id="add-new-flow-dialog-title">
                Updating Flow Info
              </DialogHeader>
            )}
            {isEdit === false && (
              <DialogHeader id="add-new-flow-dialog-title">
                Adding a new Flow
              </DialogHeader>
            )}
            <Label htmlFor="input-flow-name">Flow Name</Label>
            <InputContainer className="v-flex-row">
              <Input
                id="input-flow-name"
                type="text"
                maxLength={500}
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </InputContainer>

            <Label htmlFor="textarea-flow-description">
              Flow Description (Please review this description with Visa Product
              team)
            </Label>
            <InputContainer className="v-flex-row">
              <Textarea
                fixed
                id="textarea-flow-description"
                name="textarea-description"
                maxLength={15000}
                onChange={(e) => setDesc(e.currentTarget.value)}
                value={desc}
                style={{ blockSize: "90px" }}
              />
            </InputContainer>

            {isEdit === false && (
              <>
                <Label htmlFor="select-base-template">Base Template</Label>
                <InputContainer style={{ paddingTop: 0 }}>
                  <Select
                    id="select-base-template"
                    name="select-inline-example"
                    onChange={(event) => {
                      console.log("selection change");
                      setSelectedTemplate(event.currentTarget.value);
                    }}
                  >
                    {templates.map((template) => (
                      <option
                        value={template.flowTemplateId}
                        key={template.flowTemplateId}
                      >
                        {template.flowTemplateName}
                      </option>
                    ))}
                  </Select>
                  <InputControl>
                    <VisaChevronDownTiny />
                  </InputControl>
                </InputContainer>
              </>
            )}

            <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-end">
              {isEdit === true && (
                <Button onClick={() => submitCreate()}>Update</Button>
              )}
              {isEdit === false && (
                <Button onClick={() => submitCreate()}>Create</Button>
              )}
              <Button
                colorScheme="secondary"
                onClick={() => ref.current?.close()}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
          <DialogCloseButton onClick={() => ref.current?.close()} />
        </Dialog>
      </>
    );
  }
);
