import { Typography } from "@visa/nova-react";
import React, { useState, useEffect } from "react";
import apiConfig from "../utils/apiConfig";
import { SuccessBanner } from "./menu/SuccessBanner";
import { ProgressBar } from "./menu/ProgressBar";
import { FailedBanner } from "./menu/FailedBanner";
import {
  Label,
  Input,
  InputContainer,
  Button,
  Utility,
  Divider,
} from "@visa/nova-react";
import { LOGIN_PATH, isSandboxEnvironment } from "../utils/authUtils";

const Configuration = () => {
  const [bid, setBid] = useState(null);
  const [clinentConfig, setClinentConfig] = useState(null);
  const [agentName, setAgentName] = useState(clinentConfig?.agentName || "");
  const [bankName, setBankName] = useState(clinentConfig?.bankName || "");
  const [contactNumber, setContactNumber] = useState(
    clinentConfig?.contactNumber || ""
  );
  const [contactEmail, setContactEmail] = useState(
    clinentConfig?.contactEmail || ""
  );
  const [stopPaymentDuration, setStopPaymentDuration] = useState(
    clinentConfig?.stopPaymentDuration || ""
  );

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailed, setShowFailed] = useState(false);
  const [envProfile, setEnvProfile] = useState("");

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      setBid(currentUser.userBid);
    } else {
      window.location = LOGIN_PATH;
    }

    loadSettings();
  }, [bid]);

  const loadSettings = () => {
    if (bid !== null) {
      setShowProgressBar(true);
      apiConfig
        .get("/banking-assistant-web/v1/clientconfig/bid/" + bid)
        .then((res) => {
          setClinentConfig(res.data);
          setFields(res.data);
          setShowProgressBar(false);
          console.log("env: profile: ", res.headers["x-env-profile"]);
          setEnvProfile(res.headers["x-env-profile"]);

          console.log(res.data);
          console.log(clinentConfig);
        })
        .catch(function (error) {
          console.log(error);
          setShowProgressBar(false);
          setErrorMessage("Could not load Configuration");
          setShowFailed(true);
        });
    }
  };

  const saveChanges = () => {
    console.log(" ========== SAVE =========== ");
    setShowProgressBar(true);

    apiConfig
      .post("/banking-assistant-web/v1/clientconfig/bid/" + bid, {
        bid: bid,
        agentName: agentName,
        bankName: bankName,
        contactNumber: contactNumber,
        contactEmail: contactEmail,
        stopPaymentDuration: stopPaymentDuration,
      })
      .then((res) => {
        console.log(res);
        setShowProgressBar(false);
        setSuccessMessage("Save Successful");
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 3000);
        setShowFailed(false);
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data.message);
        setShowProgressBar(false);
        setShowSuccess(false);
        setShowFailed(true);
        setTimeout(() => {
          setShowFailed(false);
        }, 3000);
      });
  };

  const setFields = (clinentConfig) => {
    setAgentName(clinentConfig.agentName);
    setBankName(clinentConfig.bankName);
    setContactNumber(clinentConfig.contactNumber);
    setContactEmail(clinentConfig.contactEmail);
    setStopPaymentDuration(clinentConfig.stopPaymentDuration);
  };

  return (
    <>
      {showSuccess && <SuccessBanner message={successMessage} />}
      {showFailed && <FailedBanner message={errorMessage} />}
      {showProgressBar && <ProgressBar />}
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          marginTop: 20,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        <Typography variant="subtitle-1" style={{ marginBottom: 20 }}>
          Client Configuration
        </Typography>
        <Typography variant="body-1" style={{ marginBottom: 30 }}>
          Update Agent and related configuration here
        </Typography>
        <Label htmlFor="input-agent-name">Agent Name</Label>
        <InputContainer className="v-flex-row">
          <Input
            id="input-agent-name"
            type="text"
            maxLength={255}
            value={agentName}
            onChange={(e) => setAgentName(e.currentTarget.value)}
          />
        </InputContainer>
        <Label htmlFor="input-bank-display-name">Bank Display Name</Label>
        <InputContainer className="v-flex-row">
          <Input
            id="input-bank-display-name"
            type="text"
            maxLength={255}
            value={bankName}
            onChange={(e) => setBankName(e.currentTarget.value)}
          />
        </InputContainer>
        <Label htmlFor="input-bank-display-name">Bank Contact Number</Label>
        <InputContainer className="v-flex-row">
          <Input
            id="input-bank-display-name"
            type="text"
            maxLength={255}
            value={contactNumber}
            onChange={(e) => setContactNumber(e.currentTarget.value)}
          />
        </InputContainer>
        <Label htmlFor="input-bank-display-name">Bank Contact Email</Label>
        <InputContainer className="v-flex-row">
          <Input
            id="input-bank-display-name"
            type="text"
            maxLength={255}
            value={contactEmail}
            onChange={(e) => setContactEmail(e.currentTarget.value)}
          />
        </InputContainer>
        <Divider style={{ marginBottom: "30px" }}></Divider>
        <Typography variant="subtitle-2" style={{ marginBottom: 20 }}>
          Stop Payment Settings
        </Typography>

        <Label htmlFor="input-stop-payment-duration">
          Stop Payment Duration (Days)
        </Label>
        <InputContainer className="v-flex-row">
          <Input
            id="input-stop-payment-duration"
            type="number"
            value={stopPaymentDuration}
            onChange={(e) => setStopPaymentDuration(e.currentTarget.value)}
          />
        </InputContainer>

        <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-start">
          <Utility tag="span" vFlexGrow>
            <Button
              onClick={() => saveChanges()}
              disabled={isSandboxEnvironment(envProfile)}
            >
              Save
            </Button>
            {isSandboxEnvironment(envProfile) && (
              <span class="sandbox-info-span">
                Configuration cannot be changed in Sandbox Environment.
              </span>
            )}
          </Utility>
          <Button colorScheme="secondary" onClick={() => loadSettings()}>
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

export default Configuration;
