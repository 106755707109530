import { Typography } from "@visa/nova-react";
import React, { useState, useEffect } from "react";
import apiConfig from "../utils/apiConfig";
import { SuccessBanner } from "./menu/SuccessBanner";
import { ProgressBar } from "./menu/ProgressBar";
import { FailedBanner } from "./menu/FailedBanner";
import {
  Label,
  Textarea,
  InputContainer,
  Button,
  Utility,
  Divider,
} from "@visa/nova-react";
import { isSandboxEnvironment } from "../utils/authUtils";

const SystemConfiguration = () => {
  const [defaultPrompt, setDefaultPrompt] = useState("");
  const [filterPrompt, setFilterPrompt] = useState("");
  const [railPrompt, setRailPrompt] = useState("");
  const [statePrompt, setStatePrompt] = useState("");
  const [transactionHistoryPrompt, setTransactionHistoryPrompt] = useState("");
  const [multichoicePrompt, setMultichoicePrompt] = useState("");
  const [multichoiceIntentPrompt, setMultichoiceIntentPromptPrompt] = useState("");
  const [multichoiceClassifierPrompt, setMultichoiceClassifierPrompt] = useState("");
  const [multichoiceGeneratorPrompt, setMultichoiceGeneratorPrompt] = useState("");

  const [systemConfig, setSystemConfig] = useState("");

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailed, setShowFailed] = useState(false);
  const [envProfile, setEnvProfile] = useState("");

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    loadSettings();
  }, []);

  const loadSettings = () => {
    setShowProgressBar(true);
    apiConfig
      .get("/banking-assistant-web/v1/systemconfig")
      .then((res) => {
        setSystemConfig(res.data);
        setFields(res.data);
        setShowProgressBar(false);
        console.log("env: profile: ", res.headers["x-env-profile"]);
        setEnvProfile(res.headers["x-env-profile"]);

        console.log(res.data);
        console.log(systemConfig);
      })
      .catch(function (error) {
        console.log(error);
        setShowProgressBar(false);
        setErrorMessage("Could not load Configuration");
        setShowFailed(true);
      });
  };

  const saveChanges = () => {
    console.log(" ========== SAVE =========== ");
    setShowProgressBar(true);

    apiConfig
      .post("/banking-assistant-web/v1/systemconfig", {
        "default.prompt": defaultPrompt,
        "filter.prompt": filterPrompt,
        "rail.prompt": railPrompt,
        "state.prompt": statePrompt,
        "transaction_history.prompt": transactionHistoryPrompt,
        "multichoice.prompt": multichoicePrompt,
        "multichoice_intent.prompt": multichoiceIntentPrompt,
        "multichoice_classifier.prompt": multichoiceClassifierPrompt,
        "multichoice_generator.prompt": multichoiceGeneratorPrompt
      })
      .then((res) => {
        console.log(res);
        setShowProgressBar(false);
        setSuccessMessage("Save Successful");
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 3000);
        setShowFailed(false);
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data.message);
        setShowProgressBar(false);
        setShowSuccess(false);
        setShowFailed(true);
        setTimeout(() => {
          setShowFailed(false);
        }, 3000);
      });
  };

  const setFields = (systemConfig) => {
    setDefaultPrompt(systemConfig["default.prompt"]);
    setFilterPrompt(systemConfig["filter.prompt"]);
    setRailPrompt(systemConfig["rail.prompt"]);
    setStatePrompt(systemConfig["state.prompt"]);
    setTransactionHistoryPrompt(systemConfig["transaction_history.prompt"]);
    setMultichoicePrompt(systemConfig["multichoice.prompt"]);
    setMultichoiceIntentPromptPrompt(systemConfig["multichoice_intent.prompt"]);
    setMultichoiceClassifierPrompt(systemConfig["multichoice_classifier.prompt"]);
    setMultichoiceGeneratorPrompt(systemConfig["multichoice_generator.prompt"]);
  };

  return (
    <>
      {showSuccess && <SuccessBanner message={successMessage} />}
      {showFailed && <FailedBanner message={errorMessage} />}
      {showProgressBar && <ProgressBar />}
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          marginTop: 20,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        <Typography variant="subtitle-1" style={{ marginBottom: 20 }}>
          System Configuration
        </Typography>
        <Typography variant="body-1" style={{ marginBottom: 30 }}>
          Update System Configuration here. This configruation is shared accross
          clients.
        </Typography>
        <Typography variant="body-1" style={{ marginBottom: 30 }}>
          Prompts
        </Typography>
        <Label htmlFor="text-area-defaultPrompt">Default Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id="text-area-defaultPrompt"
            name="textarea-defaultPrompt"
            onChange={(e) => setDefaultPrompt(e.currentTarget.value)}
            value={defaultPrompt}
            style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="textarea-filterPrompt">Filter Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id="text-area-filterPrompt"
            name="textarea-filterPrompt"
            onChange={(e) => setFilterPrompt(e.currentTarget.value)}
            value={filterPrompt}
            style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-railPrompt">Rail Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id="text-area-railPrompt"
            name="textarea-railPrompt"
            onChange={(e) => setRailPrompt(e.currentTarget.value)}
            value={railPrompt}
            style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-statePrompt">State Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
            fixed
            id="text-area-statePrompt"
            name="textarea-statePrompt"
            onChange={(e) => setStatePrompt(e.currentTarget.value)}
            value={statePrompt}
            style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-transactionHistoryPrompt">Transaction History Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
              fixed
              id="text-area-transactionHistoryPrompt"
              name="textarea-transactionHistoryPrompt"
              onChange={(e) => setTransactionHistoryPrompt(e.currentTarget.value)}
              value={transactionHistoryPrompt}
              style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-multichoicePrompt">Multi Choice Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
              fixed
              id="text-area-multichoicePrompt"
              name="textarea-multichoicePrompt"
              onChange={(e) => setMultichoicePrompt(e.currentTarget.value)}
              value={multichoicePrompt}
              style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-multichoiceIntentPrompt">Multi Choice Intent Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
              fixed
              id="text-area-multichoiceIntentPrompt"
              name="textarea-multichoiceIntentPrompt"
              onChange={(e) => setMultichoiceIntentPromptPrompt(e.currentTarget.value)}
              value={multichoiceIntentPrompt}
              style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-multichoiceClassifierPrompt">Multi Choice Classifier Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
              fixed
              id="text-area-multichoiceClassifierPrompt"
              name="textarea-multichoiceClassifierPrompt"
              onChange={(e) => setMultichoiceClassifierPrompt(e.currentTarget.value)}
              value={multichoiceClassifierPrompt}
              style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Label htmlFor="text-area-multichoiceGeneratorPrompt">Multi Choice Generator Prompt</Label>
        <InputContainer className="v-flex-row">
          <Textarea
              fixed
              id="text-area-multichoiceGeneratorPrompt"
              name="textarea-multichoiceGeneratorPrompt"
              onChange={(e) => setMultichoiceGeneratorPrompt(e.currentTarget.value)}
              value={multichoiceGeneratorPrompt}
              style={{ blockSize: "180px" }}
          />
        </InputContainer>

        <Divider style={{ marginBottom: "30px" }}></Divider>

        <div className="v-flex v-flex-wrap v-gap-8 v-pt-16 v-align-items-center v-justify-content-start">
          <Utility tag="span" vFlexGrow>
            <Button
              onClick={() => saveChanges()}
              disabled={isSandboxEnvironment(envProfile)}
            >
              Save
            </Button>
            {isSandboxEnvironment(envProfile) && (
              <span class="sandbox-info-span">
                Configuration cannot be changed in Sandbox Environment.
              </span>
            )}
          </Utility>
          <Button colorScheme="secondary" onClick={() => loadSettings()}>
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

export default SystemConfiguration;
