import { Typography } from "@visa/nova-react";
import React from "react";

const About = () => {
  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        marginTop: 20,
        minHeight: "calc(100vh - 155px)",
      }}
    >
      <Typography variant="subtitle-1" style={{ marginBottom: 20 }}>
        What is Visa Banking Assist?
      </Typography>
      <Typography variant="body-1" style={{ marginBottom: 30 }}>
        Visa Banking Assist (VBA) is a solution that allows issuers to easily
        configure and deploy a digital assistant for their customers. With
        advanced NLP capabilities, cardholders can seamlessly interact with the
        assistant to fulfill their banking related requests. Our solution offers
        built in connectivity to Visa products and systems, empowering the
        assistant with actionable capabilities that can be fully managed by
        issuers. VBA offers strong guardrails and securely handles any sensitive
        cardholder data.
      </Typography>
      <Typography variant="subtitle-1" style={{ marginBottom: 20 }}>
        What is the Flow Designer tool?
      </Typography>
      <Typography variant="body-1" style={{ marginBottom: 50 }}>
        The flow designer tool allows issuers to easily control and customize
        the type of interactions their customers have with the banking
        assistant, as well as the rules around what the assistant can do on the
        customer's behalf. Through the flow designer tool, issuers can build and
        manage dialog flows. These flows determine the sequence of interactions
        between the assistant and the customer. The tool connects directly to
        the consumer facing banking assistant, so that flows published in the
        flow designer tool are live through the assistant.
      </Typography>
      <Typography variant="body-1" style={{ marginBottom: 30 }}>
        If you have any additional questions about VBA please contact your Visa
        representative.
      </Typography>
    </div>
  );
};

export default About;
